import { graphql, Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import Program from '../components/program'
import { Helmet } from 'react-helmet'

export default class EditionConference extends React.Component {
  constructor() {
    super()
    this.state = {
      pages: [],
    }
  }

  componentDidMount() {
    const { data } = this.props

    let { edges: events } = data.events
    const edition = data.edition

    let conferenceEvents = events.filter(e => e.node.eventType && (e.node.eventType.name.includes('Conference') || (e.node.eventType.name.includes('Musical') && !e.node.eventType.name.includes('TV'))))
    let workshopEvents = events.filter(e => e.node.eventType && e.node.eventType.name.includes('Workshop') && !e.node.eventType.name.includes('Conference'))
    let meetupEvents = events.filter(e => e.node.eventType && e.node.eventType.name.includes('Meetup'))
    let streamEvents = events.filter(e => e.node.eventType && e.node.eventType.name.includes('TV'))

    const pages = []

    if (meetupEvents.length > 0) pages.push({ name: "Meetups", title: "Meetups", slug: "program-meetups", content: meetupEvents })
    if (workshopEvents.length > 0) pages.push({ name: "Workshops", title: "Workshops", slug: "program-workshops", content: workshopEvents })
    if (conferenceEvents.length > 0) pages.push({ name: "Conference", title: "Conference", slug: "program-conference", content: conferenceEvents })
    if (streamEvents.length > 0) pages.push({ name: "Fluxible TV", title: "TV", slug: "program-tv", content: streamEvents })


    if (pages.length === 0) {
      if (edition.editionTypeId === 1) pages.push({ name: "Conference", title: "Conference", slug: "program-conference", content: [] })
      if (edition.editionTypeId === 2) pages.push({ name: "Fluxible TV", title: "TV", slug: "program-tv", content: [] })
    }

    this.setState({ pages })

  }

  render() {
    const { data } = this.props
    let { pathname } = this.props.location
    const pathParts = pathname.split("/")
    pathname = pathParts[pathParts.length - 1].length !== 0 ? pathParts[pathParts.length - 1] : pathParts[pathParts.length - 2]

    if (!data || !data.events) return null;

    const edition = data.edition

    console.log("PATHNAME", pathname)

    let metaTitle = `${edition.name} Program`
    let metaDescription = `Check out the speakers and events you can find at ${edition.name}`
    if (pathname === 'program-conference') {
      metaTitle = `${edition.name} Program`
    }
    if (pathname === 'program-workshops') {
      metaTitle = `${edition.name} Workshops`
      metaDescription = `Check out ${edition.name}'s workshops`
    }
    if (pathname === 'program-meetups') {
      metaTitle = `${edition.name} Meetups`
      metaDescription = `Check out ${edition.name}'s meetups`
    }
    const meta = [
      { property: 'og:title', content: metaTitle },
      { property: 'og:description', content: metaDescription },
    ]

    return (
      <Layout>
        {this.state.pages.length === 1 && (
          <h1 className="page-header">{`Fluxible ${this.state.pages[0].title} ${edition.name.match(/\d{4}/g)}`} Program</h1>
        )}
        {this.state.pages.length !== 1 && (<h1 className="page-header">{edition.name} Program</h1>)}
        <Helmet title={`Program | ${edition.name}`} meta={meta} />

        {this.state.pages.length > 1 && (<nav aria-label="program" className="program-menu">
          {this.state.pages.map((page, idx) => (
            <span className={page.slug === pathname ? "active" : ""} key={idx}><Link to={`${edition.fields.slug}${page.slug}`}><span>{page.name}</span></Link></span>
          ))}
        </nav>)}


        {/*<div className="text-center program-container">
          <p><Link to={`/media/2019/09/Fluxible-AtGlance-Conference_2019.pdf`}>Download a PDF</Link> that has all the details you’ll need for parking, transit, and more for Fluxible Conference.</p>
          </div>*/}
        {this.state.pages.map((page, idx) => {
          if (page.slug === pathname && page.content.length > 0) {
            return (
              <Program key={idx} data={page.content} />
            )
          } else if (page.slug === pathname) {
            return (
              <div className="text-center program-container">
                <p>{edition.editionTypeId === 2 ? 'Fluxible TV program' : 'Conference details'} to be announced. <Link to="/subscribe">Join our spam-free mailing list</Link> to receive program updates.</p>
              </div>
            )
          } else {
            return null
          }
        })}
        {this.state.pages.length === 0 &&
          <div className="callout secondary large text-center">
            <p>{edition.editionTypeId === 2 ? 'Fluxible TV program' : 'Conference details'} to be announced. <Link to="/subscribe">Join our spam-free mailing list</Link> to receive program updates.</p>
          </div>
        }
      </Layout>
    )
  }
}

EditionConference.propTypes = {
  data: PropTypes.shape({
    allFluxibleEvents: PropTypes.shape({
      edges: PropTypes.array,
    }),
    fluxibleEditions: PropTypes.object,
  }),
}

export const editionGeneralQuery = graphql`
  query EditionGeneralQuery($editionId: Int!) {
    events: allFluxibleEvents(
      sort: { order: DESC, fields: [name] }
      filter: { editionId: { eq: $editionId } }
    ) {
      edges {
        node {
          id
          name
          summary
          description
          startDate
          endDate
          eventType {
            name
            displayName
          }
          edition {
            name
          }
          speakers {
            name
            photo {
              name
              altText
              contentType
            }
          }
          location {
            name
            fields{
              slug
            }
          }
          fields {
            slug
          }
        }
      }
    }
    edition: fluxibleEditions(alternative_id: { eq: $editionId }
      ) {
      name
      startDate
      endDate
      editionTypeId
      fields {
        slug
      }
    }
  }
`
