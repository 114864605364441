import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { v4 } from 'uuid'

import { FluxibleIconBlock } from '../components/FluxibleIcon'

import { getImage, normalizeObjectList } from '../util'
import { formatDate, formatHours, getEventSummary, isUpcomingEvent, RAW_DATE_FORMAT, TZ } from '../util/events'


export default class Program extends React.Component {
  constructor(props) {
    super(props)
    const { data } = props;
    if (!data) return;

    const eventsList = normalizeObjectList(data, [
      'id', 'endDate', 'startDate', 'eventType', 'speakers', 'location', 'name', 'summary', 'description',
      { fields: ['slug'] },
    ], 'id');

    const events = Object.keys(eventsList)
      .filter((key) => eventsList[key].name !== '' && eventsList[key].eventType && eventsList[key].eventType.name !== 'Fluxible Sponsors')
      .map((key) => {
        return eventsList[key];
      }).sort((a, b) => {
        if (moment.tz(a.startDate, TZ) >= moment.tz(b.startDate, TZ)) {
          return 1;
        } else {
          return -1;
        }
      });

    // group events by date
    let eventsByDates = {}
    events.forEach((event) => {
      const date = moment.tz(event.startDate, TZ).format(RAW_DATE_FORMAT)
      if (!eventsByDates[date]) {
        eventsByDates[date] = []
      }
      eventsByDates[date].push(event)
    })

    this.state = { eventsByDates }
  }

  getSummary(event) {
    if (event.eventType.name.includes('Music') && isUpcomingEvent(event.startDate)) {
      return ""
    } else {
      return getEventSummary(event.summary, event.description)
    }
  }

  getTitle(event) {
    if (!event.eventType) return event.name
    if (event.eventType.name.toLowerCase().includes('break')) {
      if (event.name.includes('Lunch')) {
        return 'Lunch'
      } else if (event.name.includes('Break')) {
        return 'Break'
      } else if (event.name.includes('Welcome')) {
        return 'Welcome and kickoff'
      } else {
        return event.name
      }
    } else if (event.eventType.name.includes('Music') && isUpcomingEvent(event.startDate)) {
      return 'Performer to be revealed'
    } else {
      return (
        <Link id={event.slug} to={`/${event.slug}`}>
          {event.name}
        </Link>
      )
    }
  }

  getType(event) {
    if (!event.eventType) return null

    if (
      event.eventType.name.toLowerCase().includes('break') ||
      event.eventType.name.includes('party')
    )
      return null

    return event.eventType.displayName;
  }

  render() {
    const { eventsByDates } = this.state;
    if (!eventsByDates) return null;

    return (
      <div className="program grid-container">
        {Object.keys(eventsByDates).map((key) => {
          const eventsList = eventsByDates[key]
          return (
            <div key={v4()} className="grid-x">
              <div className="cell medium-8 medium-offset-2">
                <div id={moment.tz(eventsList[0].startDate, TZ).format(RAW_DATE_FORMAT)} className="grid-x grid-padding-x fluxible_date_header program_date_header">
                  {formatDate(eventsList[0].startDate)}
                  <span className="timezone">(All times in {moment.tz(TZ).zoneAbbr()})</span>
                </div>
                {eventsList.map((event) => {
                  return (
                    <div key={event.id} ref={(section) => { if (this.currentEvent === event) this.currentEvent.div = section }}>
                      <div className='program_event'>

                        <div className="program_event_time_column">
                          <div className="program_start_time">{formatHours(event.startDate)}</div>
                          {event.eventType && !event.eventType.name.includes('Meetup') &&
                            (<div className="program_speakers">
                              {(event.eventType.name.includes('Music') && isUpcomingEvent(event.startDate)) ? "" :
                                event.speakers && event.speakers.map(speaker => speaker.photo.name.includes('default') ? null : getImage(speaker.photo, speaker.name, 'program-speaker-photo'))
                              }
                            </div>)}
                        </div>

                        <div className="program_event_details_column">
                          <div className="fluxible_event_type">
                            {this.getType(event)}
                          </div>
                          <div className="program_event_title">
                            {this.getTitle(event)}
                          </div>
                          <div className="program_speakers">
                            {(event.eventType.name.includes('Music') && isUpcomingEvent(event.startDate)) ? "" : event.speakers && event.speakers.map(speaker => speaker.name).join(", ")}
                          </div>
                          <div className="program_event_summary">
                            {this.getSummary(event)}
                          </div>
                          <div className="fluxible_location">
                            {event.location ?
                              event.location.fields ?
                                <Link to={event.location.fields.slug}><FluxibleIconBlock icon="building" text={event.location.name} size="sm" /></Link>
                                : <FluxibleIconBlock icon="building" text={event.location.name} size="sm" />
                              : ""}
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )

        })

        }

      </div>
    )
  }
}

Program.propTypes = {
  data: PropTypes.array,
}
